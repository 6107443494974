import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(
              private currencyPipe: CurrencyPipe
  ) {}

  transform(value: number,  currencyGet: string = '', digitsInfo: string = '1.2-2'): any {
    const formattedValue = this.currencyPipe.transform(value, currencyGet, 'symbol', '1.2-2');
    // En lugar de obtener el símbolo de la moneda usando getCurrencySymbol, vamos a extraerlo directamente de formattedValue
    const matches = formattedValue.match(/([^\d,.]+)/);
    const extractedSymbol = matches ? matches[1].trim() : '';
    // Usa una expresión regular para extraer solo el número (y decimales) del valor formateado
    const numberValueRaw = formattedValue.match(/[\d,.]+/)[0];
    // Si el valor termina en ,00, lo eliminamos.
    const numberValue = numberValueRaw.endsWith('.00') ? numberValueRaw.slice(0, -3) : numberValueRaw;
    // Concatena el número (sin ,00 si aplica), el símbolo de la moneda extraído (con un espacio adicional) y el código de moneda en el formato deseado
    return `${numberValue} ${extractedSymbol} (${currencyGet})`;
  }

}
