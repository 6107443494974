export const environment = {
  production: false,
  platform_id: '11posts',
  hmr: false,
  aws_resources: {
    lambda_endpoint: {
      api: 'https://api-test.binfluencer.io/',
    },
  }
};
