import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from "./features/shared/shared.module";
import {CoreModule} from "./core/core.module";
import {AppStoreModule} from "./store/store.module";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {CustomHttpInterceptor} from "./core/http/http-interceptor";
import {CurrencyPipe} from "@angular/common";


@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppStoreModule,
    AppRoutingModule,
    CoreModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    CurrencyPipe
      ],
  bootstrap: [AppComponent]
})
export class AppModule { }
